import { Paths } from '@rmvw/x-common';
import * as React from 'react';

import { IS_DESKTOP_APP, IS_MOBILE_APP } from '../../env';
import Logger from '../../lib/observability/Logger';
import { useAppMeetingState } from '../../providers/AppMeetingStateProvider';
import { IWebMeetingWindowStateMessage } from '../../types/IWebMeetingWindowStateMessage';

const MEETING_WINDOW_HEIGHT = 720;
const MEETING_WINDOW_WIDTH = 1280;
const TARGET_WINDOW_ID = '#meeting-time';

/**
 * Manages the web app meeting window state
 */
export default function useWebMeetingWindowManager() {
  const { hideMeeting, meetingState } = useAppMeetingState();
  const [meetingWindow, setMeetingWindow] = React.useState<Window | null>(null);

  /**
   * Subscribe to meeting window state change events
   */
  React.useEffect(() => {
    if (IS_DESKTOP_APP || IS_MOBILE_APP) {
      return; // not applicable to desktop or mobile
    }

    const _listener = (event: MessageEvent<IWebMeetingWindowStateMessage>) => {
      if (event.origin !== window.location.origin) {
        // Security: invalid origin of message
        return;
      }

      const { data } = event;
      switch (data?.type) {
        case 'MEETING_WINDOW_STATE': {
          if (!data.state?.joined && data.state.seriesCode === meetingState.seriesCode) {
            hideMeeting(); // auto-hide meeting window if meeting has ended
          }
          break;
        }

        default: {
          // Ignore
          return;
        }
      }
    };

    window.addEventListener('message', _listener);
    return () => window.removeEventListener('message', _listener);
  }, [hideMeeting, meetingState]);

  /**
   * Manage window state
   */
  React.useEffect(() => {
    if (IS_DESKTOP_APP || IS_MOBILE_APP) {
      return; // not applicable to desktop or mobile
    }

    if (meetingState.seriesCode) {
      const meetingUrl = `${window.location.origin}${Paths.MEETING_WINDOW(
        meetingState.seriesCode,
        meetingState.options
      )}`;
      const mw =
        meetingWindow?.location.href === meetingUrl
          ? meetingWindow
          : window.open(
              meetingUrl,
              TARGET_WINDOW_ID,
              [
                'popup',
                `width=${Math.min(MEETING_WINDOW_WIDTH, window.screen.width)}`,
                `height=${Math.min(MEETING_WINDOW_HEIGHT, window.screen.height)}`,
              ].join(',')
            );

      if (!mw) {
        Logger.warn('[useWebMeetingWindowManager] Unable to open meeting window');
        hideMeeting();
        // vvv fall through
      } else {
        mw.focus(); // Not guaranteed to work...but it's better than nothing
      }

      setMeetingWindow(mw);
    } else {
      meetingWindow?.close();
      setMeetingWindow(null);
    }
  }, [hideMeeting, meetingState, meetingWindow]);
}
